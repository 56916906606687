.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.errorText {
  color: #ff0000;
  font-size: 12px;
  font-weight: 500;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Landing Page */
* {
  box-sizing: border-box;
}
/* In your CSS file or inline styles */
a {
  color: inherit; /* Use the color inherited from the parent */
  text-decoration: none; /* Remove underline */
}

body {
  margin: 0;
}
.godenty-container {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  position: relative;
}
.top-section-background {
  background-image: url("./assets/images/TopBgImage.png");
  margin-top: 60px;
  padding: 20px;
  margin-right: 0px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 80%);
  height: 400px;
  width: 100%;
}
.middle-section-background {
  background: rgba(255, 255, 255, 1);
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 95%;
}
.middle-section-cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 100%;
  margin-bottom: 5%;
  background-image: url("./assets/images/middleCardRightBg.png");
  background-size: cover;
  border-radius: 45px;
}
.middle-section-cards-second {
  background-image: url("./assets/images/middleCardLeftbg.png");
}
.godenty-footer-container {
  background-image: url("./assets/images/footerBg.png");
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}
.footer-text {
  height: 60%;
  width: 30%;
}
.middle-section-cards h3 {
  font-weight: 700;
  color: rgba(24, 46, 75, 1);
}
.middle-section-cards p {
  color: rgba(158, 158, 158, 1);
  font-weight: 500;
}
.middle-section-cards img {
  height: 60%;
  width: 80%;
}
.godenty-navbar {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  background: rgba(5, 11, 38, 1);
  overflow: auto;
}
.godenty-navbar-links {
  display: flex;
  align-items: center;
  overflow: auto;
  gap: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
  list-style: none;
}
.godenty-navbar-links li {
  cursor: pointer;
}
.signup-button {
  width: 92px;
  height: 41px;
  border-radius: 25px;
  border: solid 1px rgba(41, 39, 53, 1);
  background-color: rgb(41, 39, 53, 1);
  padding: 10px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.active-tab-link {
  color: rgba(216, 120, 56, 1);
  text-decoration: underline;
  text-underline-offset: 4px;
}
.headings-h1 {
  color: white;
  font-size: 2rem;
  font-weight: 700;
}
.span-color {
  color: rgba(255, 134, 96, 1);
}
.rounded-labels {
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 20%;
  border: 21px solid rgba(255, 134, 96, 1);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
}
.rounded-labels img {
  width: 80%;
  display: block;
  margin: 0 auto;
  margin-bottom: 2px;
}
.top-section-card {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.footer-signUp {
  height: 30%;
  width: 20%;
}
.about-vissionMissionContainer {
  padding: 7%;
  display: flex;
  flex-direction: column;
}
.about-vissionMissionContainer h1 {
  color: rgba(41, 41, 41, 1);
  font-weight: 500;
}
.about-vissionMissionCard {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10%;
}
.about-vissionMissionCard img {
  width: 45%;
}
.about-vissionMissionCard p {
  width: 40%;
  color: rgba(158, 158, 158, 1);
  font-size: 24px;
  font-weight: 500;
}
.vissionCardImages {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}
.smallRectangleBg {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 110px;
}
.visionDoctorImg-position {
  position: absolute;
  /* right: 50%; */
  left: 52%;
  top: 30%;
}
.vissionSmallImg-position {
  position: absolute;
  right: 50%;
}
.visionStetothScopeImg-position {
  position: absolute;
  bottom: 0;
  right: 29%;
  z-index: 2;
}
.visionTeethImg-position {
  position: absolute;
  bottom: -319px;
  left: 230px;
  z-index: 2;
  background: #ffffff;
  transform: rotate(23deg);
}
.responsiveMargin {
  margin-bottom: 15%;
}
.muchMoreContainer {
  padding: 5%;
}
.muchmoreListitem {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 10px;
  display: inline-block;
  padding: 1%;
  color: rgba(190, 190, 190, 1);
}
.opacityImage1 {
  opacity: 0.5;
  position: absolute;
  right: 250px;
  bottom: 186px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.opacityImage2 {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 44px;
}
.middleLoginBg img {
  width: 90%;
  height: 50%;
}
.missionLoveimg {
  transform: rotate(-20deg);
}
.goDentyImagBG {
  transform: rotate(17deg);
  background-color: #ffffff;
  margin-bottom: -20%;
  position: absolute;
  left: 480px;
}
.middle-section-background-desktop {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: unset;
  position: relative;
  top: -170px;
  margin-bottom: -30%;
}
.SignUpPage-leftSidecontainer {
  background: linear-gradient(192.22deg, #060c27 -0.14%, #05103e 96.63%);
  width: 30%;
  padding: 2%;
  display: flex;
  flex-direction: column;
}
.signUp-formcontainer {
  width: 60%;
  height: 70%;
  margin: auto;
}
.signUp-formcontainer-input {
  width: 100%;
  /* box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.06); */
  border-radius: 10px;
  border: none;
  padding: 1rem;
  /* margin-bottom: 15px; */
  outline: none;
}
.signUp-formcontainer ::placeholder {
  color: rgba(165, 165, 165, 1);
}
#avatar-inp-label {
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .top-section-background {
    width: 100%;
    height: 100%;
  }
  .first-middle-section-card {
    position: relative;
    top: -190px;
    margin-bottom: -30%;
  }
  .headings-h1 {
    font-size: 32px;
  }
  .rounded-labels {
    width: 50%;
    border-width: 10px;
  }

  .top-section-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .middle-section-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .middle-section-cards {
    width: 90%;
    border-radius: 25px;
  }
  .middle-section-cards img {
    width: 100%;
  }
  .footer-text {
    height: 50%;
    width: 50%;
  }
  .footer-signUp {
    height: 22%;
    width: 35%;
  }
  .about-vissionMissionCard {
    flex-direction: column;
    justify-content: center;
  }
  .about-vissionMissionCard img {
    width: 100%;
  }
  .about-vissionMissionCard p {
    font-size: 1rem;
    width: 100%;
  }
  .vissionCardImages {
    margin-bottom: 20%;
  }
  .godenty-footer-container {
    flex-direction: column;
    height: 35%;
  }
  .footer-signUp {
    width: 90%;
    height: 35%;
  }
  .footer-text {
    width: 90%;
    height: 50%;
  }
  .responsiveMargin {
    margin-top: 50%;
  }
  .responsiveMarginTeeth {
    margin-bottom: 100%;
  }
  .visionTeethImg-position {
    left: 3px;
    bottom: -210px;
  }
  .middleLoginBg {
    height: 25%;
    align-items: center;
  }
  .middleLoginBg img {
    height: 80%;
    width: 100%;
  }
  .goDentyImagBG {
    transform: rotate(17deg);
    position: relative;
    top: -90px;
    right: -93px;
    background-color: #ffffff;
    margin-bottom: -20%;
    left: unset;
  }
  .middle-section-background-desktop {
    position: unset;
    margin-bottom: unset;
  }
}

.MuiModal-backdrop.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  backdrop-filter: blur(6px);
  background-color: hsla(0, 0%, 0%, 0.5);
  opacity: 1 !important;
}
.MuiDialog-paper{
  box-shadow: 5px 5px 12px 5px rgba(0, 0, 0, 0.3) !important;
}
